import { PageProps } from 'gatsby'
import React from 'react'
import { RetentionOfferPage } from 'modules/retention/pages/RetentionOfferPage'
import Layout from '../../layouts/layout'

const Invite = (props: PageProps) => {
  if (typeof window === 'undefined') return null

  return (
    <Layout>
      <RetentionOfferPage offerId={props.params.id} />
    </Layout>
  )
}

export default Invite
