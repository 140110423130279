import React from 'react'
import { ThemeState } from '../../../state/ThemeState'
import { LoaderAnimate, LoaderWrap, Placeholder, StyledLoader } from './index.styles'

interface IProps {
  color?: string,
  size?: number,
  placeholder?: string,
}

const PageLoader = ({ color, size, placeholder }: IProps) => {
  const { theme } = ThemeState.useContainer()
  return (
    <LoaderWrap color={color || theme.primary} size={size}>
      <LoaderAnimate>
        <StyledLoader color={color || theme.primary} size={size}>
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 64 64"
            xmlSpace="preserve"
          >
            <path d="M32,0C14.4,0,0,14.4,0,32s14.3,32,32,32s32-14.3,32-32C64,14.4,49.6,0,32,0z M36.3,21.2L35,22.9c-0.9,1.2-2,2.5-3,3.6c-1-1.2-2-2.5-3-3.6l-1.4-1.7c-3.6-4.4-3.6-10.1-3.3-13.1c4.9-1.6,10.4-1.6,15.2,0C39.9,11.1,39.9,16.8,36.3,21.2z M17.7,11.3c0.1,4,1.2,9.4,4.9,14l1.4,1.7c1.3,1.6,2.6,3.1,3.8,4.7c-2.9,3.5-4.9,6.2-5.2,6.5c-3.9,4.8-4.8,10.3-4.9,14.4C10.9,48,6.8,40.3,6.8,32C6.9,23.7,10.9,16,17.7,11.3z M27.7,42.3L27.7,42.3c0.4-0.4,2.1-2.5,4.3-5.5c2.5,3.1,4.3,5.3,4.3,5.5c3.8,4.7,3.6,10.8,3.3,13.7c-4.9,1.6-10.3,1.6-15.2,0C24.1,53.1,23.8,47.1,27.7,42.3z M46.3,52.7c0-4.2-1-9.6-4.9-14.4v-0.1c-0.4-0.5-2.5-3.1-5.2-6.5c1.3-1.6,2.6-3.1,3.8-4.7l1.4-1.7c3.8-4.7,4.8-10,4.9-14c6.8,4.7,10.9,12.4,10.9,20.7C57.1,40.3,53.1,48,46.3,52.7z" />
          </svg>
        </StyledLoader>
      </LoaderAnimate>
      { placeholder && <Placeholder>{ placeholder }</Placeholder> }
    </LoaderWrap>
  )
}


PageLoader.defaultProps = {
  size: 52
}

export default PageLoader
