import styled from 'styled-components';

export const Content = styled.div`
  color: #fff;
  max-width: 540px;
  margin: 0 auto;
  padding: 0 20px;
  .offer--title {
    font-family: 'Rammetto One';
    font-size: 1.5em;
    text-align: center;
    text-shadow: 0px .1em #afafaf, 0px .2em rgba(0, 0, 0, 0.3);
  }
  .offer--sub-title {
    font-family: 'Rammetto One';
    color: #ffed00;
    font-size: 1em;
    text-align: center;
  }

  .items {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;
  }
  .item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    .item--image {
      width: 240px;
      margin-right: 20px;
      display: flex;
      justify-content: center;
    }

    .item--content {
      max-width: 240px;
      .item--title {
        font-family: 'Rammetto One';
        font-size: 1.4em;
        line-height: 1.2;
        margin-bottom: 0.3em;
        text-shadow: 0px .1em #afafaf, 0px .2em rgba(0, 0, 0, 0.3);
      }
    
      .item--sub-title {
        font-family: 'Rammetto One';
        font-size: .8em;
      }

      .item--description {
        font-size: 0.7em;
      }

      .item--action {
        margin-top: 12px;
      }
    }
  }

  @media (max-width: 540px) {
    .item {
      flex-direction: column;
      width: 100%;
      .item--content {
        max-width: none;
      }
      .item--image {
        margin-bottom: 20px;
        margin-right: 0;
      }
      text-align: center;
    }
  }
`