import { gql, useQuery } from '@apollo/client'
import { navigate } from 'gatsby'
import { Button } from 'nzk-react-components'
import PageLoader from 'components/UI/PageLoader'
import React from 'react'
import styled from 'styled-components'
import { Content } from './index.styles'

interface IProps {
  offerId: string
}

const Wrapper = styled.div`
  color: #fff;
`

export const RetentionOfferPage = (props: IProps) => {
  const query = useQuery(gql`
    query getOffer($id: String!) {
      me {
        _id
        retention_offer(id: $id) {
          canClaim
          claimed
          available
          archived
          content {
            title
            subTitle
            willUnlockMessage
            worth
            items {
              imageUrl
              title
              subTitle
              description
              link {
                type
                label
                url
              }
            }
          }
        }
      }
    }
  `, {
    fetchPolicy: 'network-only',
    variables: {
      id: props.offerId
    }
  })

  const offer = query.data?.me?.retention_offer

  const download = (url) => {
    if (url) {
      window.open(url, '_blank')
    }
  }
  
  if (query.loading) return <Wrapper>
    <PageLoader />
  </Wrapper>


  if (!offer.claimed && !offer.canClaim) {
    return <Wrapper>
      You can't claim this offer at this time...
    </Wrapper>
  }

  if (offer.claimed) {
    return <Wrapper>
      <Content>
        <h1 className='offer--title'>Your gift</h1>
        { offer.content?.willUnlockMessage && <h3 className='offer--sub-title'>{offer.content.willUnlockMessage}</h3>}
        { offer.content?.items && <div className='items'>
          {
            offer.content.items.map((item, i) => {
              return <div key={i} className='item'>
                <div className='item--image'>
                  <img src={item.imageUrl} alt={item.title} />
                </div>
                <div className='item--content'>
                  <div className='item--title'>{item.title}</div>
                  <div className='item--sub-title'>{item.subTitle}</div>
                  { /* <div className='item--description'>{item.description}</div> */ }
                  <div className='item--action'>
                    { item.link && <Button
                        theme='primary'
                        size='regular'
                        onClick={() => {
                          if (item.link.type === 'DOWNLOAD') {
                            download(item.link.url)
                          } else if (item.link.type === 'INTERNAL') {
                            navigate(item.link.url)
                          }
                        }}
                      >{item.link.label}</Button>
                    }
                  </div>
                </div>
              </div>
            })
          }
        </div> }
        <div dangerouslySetInnerHTML={{ __html: offer.content?.description }} />
      </Content>
    </Wrapper>
  }



  return null
}
