import styled from 'styled-components'

export const LoaderWrap = styled.div`
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.6);
  color: ${props => props.color};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  z-index: 99991;
  animation: fadein 1s;
  @keyframes fadein {
    from { opacity: 0; }
    to { opacity: 1; }
  }
`

export const LoaderAnimate = styled.div`
	background: inherit;
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledLoader = styled.div`
  & > svg {
    display: inline-block;
    overflow: show;
    margin: auto;
    text-indent: -9999em;
    animation: spin 1.5s linear infinite;
    fill: ${props => props.color};
    height: ${props => props.size}px;
    width: ${props => props.size}px;
    @keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
  };
`

export const Placeholder = styled.div`
  margin-top: 10px;
`

